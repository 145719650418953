import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    // <Svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M8.37124 11.2583C10.4603 12.579 12.0211 14.939 12.0211 14.939H12.0525C12.0525 14.939 15.3685 9.0712 21.5291 5.4624"
    //     stroke="#E81899"
    //     strokeWidth="1.5"
    //     strokeLinecap="square"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M11.7227 21.25C16.8313 21.25 20.9727 17.1086 20.9727 12C20.9727 6.89137 16.8313 2.75 11.7227 2.75C6.61402 2.75 2.47266 6.89137 2.47266 12C2.47266 17.1086 6.61402 21.25 11.7227 21.25Z"
    //     stroke="#E81899"
    //     strokeWidth="1.5"
    //     strokeLinecap="round"
    //   />
    // </Svg>
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#00F327"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
      />
    </Svg>
  )
}

export default Icon
