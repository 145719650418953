import { useTranslation } from 'contexts/Localization'
import { Button, Flex, Text } from 'packages/uikit'
import { setupNetwork } from 'utils/wallet'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import { FlexGap } from 'components/Layout/Flex'
import styled from 'styled-components'

const Dot = styled.div`
  background: #00f327;
  border-radius: 50%;
  width: 5px;
  height: 5px;
`

const StyledButton = styled(Button)`
  background: #5f5d5f;
  border: none;
  box-shadow: none;
`

interface WalletWrongNetworkProps {
  onDismiss: () => void
}

const WalletWrongNetwork: React.FC<WalletWrongNetworkProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { connector, library } = useWeb3React()

  const handleSwitchNetwork = async (): Promise<void> => {
    await setupNetwork(library)
    onDismiss?.()
  }

  return (
    <>
      <Text color="white" mb="12px" fontSize="16px">
        {t('Wrong Network detected, switch or disconnect to continue')}
      </Text>
      {connector instanceof InjectedConnector && (
        <StyledButton onClick={handleSwitchNetwork} mb="12px">
          <Flex width="100%" justifyContent="space-between">
            <Text color="white" fontSize="16px">
              {t('Berachain')}
            </Text>

            <FlexGap alignItems="center" gap="5px">
              <Dot />
              <Text color="white" fontSize="16px">
                {t('Confirm in wallet')}
              </Text>
            </FlexGap>
          </Flex>
        </StyledButton>
      )}
    </>
  )
}

export default WalletWrongNetwork
