import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 22 22">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11 7.898L18.898 0 22 3.102 14.102 11 22 18.898 18.898 22 11 14.102 3.102 22 0 18.898 7.898 11 0 3.102 3.102 0 11 7.898z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default Icon
