import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 22">
      <path
        d="M13.2074 0.716797H1.88677C0.849045 0.716797 0 1.56584 0 2.60356V15.8109H1.88677V2.60356H13.2074V0.716797ZM16.0375 4.49033H5.6603C4.62258 4.49033 3.77353 5.33938 3.77353 6.3771V19.5845C3.77353 20.6222 4.62258 21.4712 5.6603 21.4712H16.0375C17.0752 21.4712 17.9243 20.6222 17.9243 19.5845V6.3771C17.9243 5.33938 17.0752 4.49033 16.0375 4.49033ZM16.0375 19.5845H5.6603V6.3771H16.0375V19.5845Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
