import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none" viewBox="0 0 19 19">
      <g strokeLinecap="round" strokeLinejoin="round" clipPath="url(#clip0_2690_5671)">
        <path
          stroke="#fff"
          strokeWidth="2"
          d="M5.43 15.606H2.037A1.357 1.357 0 01.68 14.25V2.035A1.357 1.357 0 012.037.678h8.143l4.071 4.071v1.357"
        />
        <path
          stroke="#FFC107"
          d="M18.21 17.113a.84.84 0 01-.275 1.099.745.745 0 01-.39.11H9.58a.745.745 0 01-.665-.408.84.84 0 010-.8l4.003-7.22a.733.733 0 011.357 0l3.936 7.22zM13.57 14.25v-1.357M13.568 16.455a.17.17 0 110-.34M13.57 16.455a.17.17 0 100-.34"
        />
      </g>
      <defs>
        <clipPath id="clip0_2690_5671">
          <path fill="#fff" d="M0 0H19V19H0z" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
