import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
      <path
        fill="#fff"
        d="M12.547 2.804l4.078 4.66c.239.273.375.649.375 1.036s-.136.763-.375 1.036l-4.078 4.66a1.057 1.057 0 01-.796.375c-.621 0-1.126-.576-1.126-1.286v-2.356h-4.25c-.588 0-1.063-.543-1.063-1.215V7.286c0-.672.475-1.215 1.063-1.215h4.25V3.715c0-.71.505-1.286 1.126-1.286.298 0 .584.136.796.375zM5.312 2.43H3.188c-.587 0-1.062.542-1.062 1.214v9.714c0 .672.475 1.214 1.063 1.214h2.124c.588 0 1.063.543 1.063 1.215C6.375 16.457 5.9 17 5.312 17H3.188C1.427 17 0 15.368 0 13.357V3.643C0 1.632 1.428 0 3.188 0h2.124C5.9 0 6.375.543 6.375 1.214c0 .672-.475 1.215-1.063 1.215z"
      />
    </Svg>
  )
}

export default Icon
