import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.85937 13.9646H1.82366C1.50161 13.9646 1.19275 13.8366 0.965031 13.6089C0.737308 13.3812 0.609375 13.0723 0.609375 12.7503V1.82171C0.609375 1.49965 0.737308 1.1908 0.965031 0.963078C1.19275 0.735355 1.50161 0.607422 1.82366 0.607422H9.10937L12.7522 4.25028V5.46456"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2991 15.3123C16.3587 15.4223 16.3898 15.5455 16.3898 15.6706C16.3898 15.7957 16.3587 15.9188 16.2991 16.0288C16.2433 16.1385 16.158 16.2306 16.053 16.2948C15.9481 16.3592 15.8272 16.3932 15.7041 16.3931H8.57631C8.45319 16.3932 8.33247 16.3592 8.22746 16.2948C8.12246 16.2306 8.03727 16.1385 7.98131 16.0288C7.9218 15.9188 7.89062 15.7957 7.89062 15.6706C7.89062 15.5455 7.9218 15.4223 7.98131 15.3123L11.5635 8.85238C11.6127 8.73175 11.6967 8.62851 11.8048 8.55583C11.913 8.48316 12.0403 8.44434 12.1705 8.44434C12.3008 8.44434 12.4282 8.48316 12.5364 8.55583C12.6445 8.62851 12.7285 8.73175 12.7777 8.85238L16.2991 15.3123Z"
        stroke="#FFC107"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.1484 12.7504V11.5361" stroke="#FFC107" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.1363 14.7237C12.0524 14.7237 11.9844 14.6557 11.9844 14.5718C11.9844 14.4879 12.0524 14.4199 12.1363 14.4199"
        stroke="#FFC107"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1328 14.7237C12.2168 14.7237 12.2848 14.6557 12.2848 14.5718C12.2848 14.4879 12.2168 14.4199 12.1328 14.4199"
        stroke="#FFC107"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default Icon
