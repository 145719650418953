import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="30" height="25" fill="none" viewBox="0 0 30 25">
      <path
        fill="#fff"
        d="M30 2.96a12.573 12.573 0 01-3.544.996A6.247 6.247 0 0029.162.469a12.107 12.107 0 01-3.9 1.527A6.093 6.093 0 0020.769 0c-3.405 0-6.146 2.835-6.146 6.31 0 .5.041.98.143 1.438-5.114-.256-9.638-2.77-12.677-6.598a6.476 6.476 0 00-.842 3.188 6.361 6.361 0 002.732 5.243A5.958 5.958 0 011.2 8.804v.07c0 3.064 2.132 5.61 4.928 6.197-.501.14-1.047.208-1.613.208-.394 0-.791-.023-1.164-.108.796 2.498 3.058 4.335 5.747 4.394a12.163 12.163 0 01-7.626 2.69c-.504 0-.988-.022-1.472-.086A17.001 17.001 0 009.435 25C20.753 25 26.94 15.385 26.94 7.05c0-.279-.01-.548-.023-.815C28.139 5.346 29.165 4.237 30 2.96z"
      />
    </Svg>
  )
}

export default Icon
