import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="none" viewBox="0 0 27 27">
      <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" clipPath="url(#clip0_2644_7489)">
        <path d="M17.36 4.822H5.787a1.929 1.929 0 00-1.929 1.929v17.357c0 1.065.864 1.929 1.929 1.929h11.571a1.929 1.929 0 001.929-1.929V6.751a1.929 1.929 0 00-1.929-1.929zM7.719 9.643h7.714M7.719 14.465h7.714M7.719 19.287h3.857" />
        <path d="M8.68.965h12.535a1.928 1.928 0 011.929 1.928v18.322" />
      </g>
      <defs>
        <clipPath id="clip0_2644_7489">
          <path fill="#fff" d="M0 0H27V27H0z" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
