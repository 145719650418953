import React from 'react'
import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import MoreHorizontal from '../../components/Svg/Icons/MoreHorizontal'
import { ButtonProps } from '../../components/Button'
import { connectorLocalStorageKey, walletConnectConfig, walletLocalStorageKey } from './config'
import { Login, Config } from './types'
import { Flex } from '../../components/Box'

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
}

const WalletButton = styled(Button).attrs({ width: '100%', variant: 'text', py: '16px' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #5f5d5f;
`

const Recent = styled.div`
  border: 2px solid var(--icon-button-color);
  padding: 4px 12px;
  border-radius: 30px;
  background: transparent;
  font-style: italic;
`

const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: var(--black-color);
`

interface MoreWalletCardProps extends ButtonProps {
  t: (key: string) => string
}

export const MoreWalletCard: React.FC<MoreWalletCardProps> = ({ t, ...props }) => {
  return (
    <WalletButton variant="tertiary" {...props}>
      <MoreHorizontal width="40px" mb="8px" color="textSubtle" />
      <Text fontSize="14px">{t('More')}</Text>
    </WalletButton>
  )
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss }) => {
  const { title, icon: Icon } = walletConfig
  const { t } = useTranslation()

  return (
    <WalletButton
      variant="tertiary"
      onClick={() => {
        // TW point to WC on desktop
        if (title === 'Trust Wallet' && walletConnectConfig && isDesktop) {
          login(walletConnectConfig.connectorId)
          localStorage?.setItem(walletLocalStorageKey, walletConnectConfig.title)
          localStorage?.setItem(connectorLocalStorageKey, walletConnectConfig.connectorId)
          onDismiss()
          return
        }
        if (!window.ethereum && walletConfig.href) {
          window.open(walletConfig.href, '_blank', 'noopener noreferrer')
        } else {
          login(walletConfig.connectorId)
          localStorage?.setItem(walletLocalStorageKey, walletConfig.title)
          localStorage?.setItem(connectorLocalStorageKey, walletConfig.connectorId)
          onDismiss()
        }
      }}
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
    >
      <Flex alignItems="center" style={{ gap: '8px' }}>
        <BoxIcon>
          <Icon width="25px" />
        </BoxIcon>

        <Text bold fontSize="16px">
          {title}
        </Text>
      </Flex>

      {title === 'Metamask' && (
        <Recent>
          <Text color="primaryBright" fontSize="16px">
            {t('Recent')}
          </Text>
        </Recent>
      )}
    </WalletButton>
  )
}

export default WalletCard
