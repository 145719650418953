import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25">
      <g clipPath="url(#clip0_405_12244)">
        <path
          fill="#0D0D0D"
          d="M5.669 13.48a1 1 0 01.774 1.84l-.108.046a2 2 0 102.497 2.696l.057-.143a1 1 0 111.885.666 4.001 4.001 0 11-5.105-5.105zm12.666 0a4.002 4.002 0 11-5.105 5.105 1 1 0 011.84-.774l.046.108a2.003 2.003 0 003.78-.02 2 2 0 00-1.084-2.477l-.143-.056a1.002 1.002 0 01-.61-1.276 1 1 0 011.276-.61zM8.013 6.892l.103.07 2.658 2.068a2 2 0 002.317.099l.139-.1 2.658-2.066a1 1 0 011.474 1.3l-.07.103-2.068 2.658a2 2 0 00-.1 2.317l.1.139 2.067 2.658a1 1 0 01-1.3 1.474l-.103-.07-2.658-2.068a2 2 0 00-2.317-.099l-.14.099-2.657 2.067a1 1 0 01-1.474-1.3l.07-.103L8.78 13.48a2 2 0 00.099-2.317l-.1-.139-2.066-2.658a1 1 0 011.192-1.53l.108.056zm8.989-3.64a4 4 0 011.333 7.772 1 1 0 01-.774-1.84l.108-.046a2.001 2.001 0 10-2.497-2.696l-.056.143a1 1 0 11-1.886-.666 4.001 4.001 0 013.772-2.667zm-10 0a4.001 4.001 0 013.772 2.667 1 1 0 01-1.84.774l-.046-.108a2.001 2.001 0 10-2.696 2.497l.143.057a1 1 0 01-.666 1.885 4.001 4.001 0 011.333-7.772z"
        />
      </g>
      <defs>
        <clipPath id="clip0_405_12244">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(0 .252)" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
