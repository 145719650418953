import { scales, variants } from './types'

export const scaleVariants = {
  [scales.MD]: {
    height: '62px',
    padding: '0 24px',
  },
  [scales.SM]: {
    height: '32px',
    padding: '0 16px',
  },
  [scales.XS]: {
    height: '20px',
    fontSize: '12px',
    padding: '0 8px',
  },
}

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: 'var(--button-color)',
    color: 'black',
    // boxShadow: 'inset 0px 0px 50px 10px rgba(77, 68, 135, 0.8)',
  },
  [variants.SECONDARY]: {
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'white',
    boxShadow: 'none',
    color: 'white',
    ':disabled': {
      backgroundColor: 'transparent',
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: 'tertiary',
    boxShadow: 'none',
    color: 'primary',
  },
  [variants.SUBTLE]: {
    backgroundColor: '#222426',
    color: 'backgroundAlt',
  },
  [variants.DANGER]: {
    backgroundColor: 'failure',
    color: 'white',
  },
  [variants.SUCCESS]: {
    backgroundColor: 'success',
    color: 'white',
  },
  [variants.TEXT]: {
    backgroundColor: 'transparent',
    color: 'primary',
    boxShadow: 'none',
  },
  [variants.LIGHT]: {
    backgroundColor: 'input',
    color: 'textSubtle',
    boxShadow: 'none',
  },
  [variants.BLASTBTN]: {
    backgroundColor: 'var(--button-color)',
    color: 'black',
    // boxShadow: 'inset 0px 0px 50px 10px rgba(77, 68, 135, 0.8)',
    // border: '1px solid #7A72AF',
  },
  [variants.BLASTDISABLED]: {
    backgroundColor: '#494949',
    color: '#ffffff',
    boxShadow: 'none',
  },
  [variants.PINK]: {
    backgroundColor: 'var(--button-color)',
    color: 'black',
    // boxShadow: 'inset 0px 0px 50px 10px rgba(77, 68, 135, 0.8)',
    border: '1px solid #7A72AF',
  },
  [variants.SLIPP]: {
    backgroundColor: 'var(--button-color)',
    color: 'var(--black-color)',
    boxShadow: 'none',
    border: '1px solid #ffffff',
  },
  [variants.SLIPP_DISABLED]: {
    backgroundColor: 'rgba(73, 73, 73, 1)',
    boxShadow: 'none',
    border: '1px solid #ffffff',
  },
  [variants.INACTIVE]: {
    backgroundColor: '#4D4487CC',
    color: 'white',
    boxShadow: 'none',
    border: '1px solid #7A72AF',
  },
  [variants.CANCEL]: {
    backgroundColor: '#383838',
    color: 'white',
    boxShadow: 'none',
  },
  [variants.TRANSPARENT]: {
    backgroundColor: 'transparent',
    color: '#18DB2B',
    boxShadow: 'none',
  },
  [variants.LINK]: {
    backgroundColor: 'transparent',
    color: 'white',
    boxShadow: 'none',
    border: '1px solid #fff',
  },
  [variants.NO_BG]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
  },
  [variants.STAKED_INACTIVE]: {
    backgroundColor: 'var(--primary-color)',
    boxShadow: 'none',
    color: 'white',
  },
}
