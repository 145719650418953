import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="30" height="22" fill="none" viewBox="0 0 30 22">
      <path
        fill="#fff"
        d="M11.772 14.5l-.497 6.142c.71 0 1.018-.269 1.387-.59l3.329-2.8 6.897 4.445c1.265.62 2.157.293 2.498-1.024l4.527-18.67h.002c.401-1.646-.677-2.29-1.91-1.886L1.394 9.083c-1.817.62-1.789 1.511-.309 1.915l6.804 1.863 15.804-8.703c.744-.433 1.42-.193.864.24L11.772 14.5z"
      />
    </Svg>
  )
}

export default Icon
